<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-spacer />
      <v-tooltip top v-if="items.length > 0">
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="loading"
            v-on="on"
            @click="
              create = !create;
            "
            icon
          >
            <v-icon v-if="!create">mdi-plus-circle</v-icon>
            <v-icon v-else>mdi-close-circle</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("create") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            href="https://netbackup.iway.ch/cbs/system/ShowDownload.do"
            target="_blank"
            v-on="on"
            icon
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Download Client") }}</span>
      </v-tooltip>

      <wiki slug="net-backup" />
    </v-toolbar>
    <v-skeleton-loader type="card-avatar, divider, table" v-if="loadingList"/>
    <v-card-text v-else>
      <v-row v-show="create || items.length === 0">
        <v-col>
          <h3 lass="text--primary">
            {{ $t("1. Download and Install Client") }}
          </h3>

          <v-img
            :lazy-src="$config.netbackupDownloadImg"
            max-height="250"
            max-width="250"
            :src="$config.netbackupDownloadImg"
          >
            <template v-slot:default>
              <a
                href="https://netbackup.iway.ch/cbs/system/ShowDownload.do"
                target="_blank"
                class="remove-underline"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-row
                      class="fill-height ma-0 pt-12"
                      align="center"
                      justify="center"
                      v-on="on"
                    >
                      <v-btn
                        href="https://netbackup.iway.ch/cbs/system/ShowDownload.do"
                        target="_blank"
                        icon
                      >
                        <v-icon size="120">mdi-download</v-icon>
                      </v-btn>
                    </v-row>
                  </template>
                  <span>{{ $t("Download Client") }}</span>
                </v-tooltip>
              </a>
            </template>
          </v-img>
        </v-col>
        <v-col>
          <h3 lass="text--primary">{{ $t("2. Create Trial Account") }}</h3>
        </v-col>
        <v-col>
          <h3 lass="text--primary">{{ $t("3. Register Trial Account") }}</h3>
          <validation-observer ref="obs" v-slot="{ invalid }">
            <validation-provider
              vid="username"
              :name="$t('username')"
              rules="required"
              v-slot="{ errors, valid, classes }"
            >
              <v-text-field
                v-model="username"
                item-text="username"
                item-value="username"
                :label="$t('username')"
                name="username"
                :error-messages="errors"
                :success="valid"
                :class="classes"
                :clearable="!isMobile"
              ></v-text-field>
            </validation-provider>
            <validation-provider
              vid="password"
              :name="$t('password')"
              rules="required"
              v-slot="{ errors, valid, classes }"
            >
              <v-text-field
                v-model="password"
                item-text="password"
                item-value="password"
                :label="$t('password')"
                name="password"
                :type="showCurrentPassword ? 'text' : 'password'"
                :append-icon="showCurrentPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="showCurrentPassword = !showCurrentPassword"
                :error-messages="errors"
                :success="valid"
                :class="classes"
                :clearable="!isMobile"
              ></v-text-field>
            </validation-provider>

            <v-btn :disabled="invalid" color="primary" @click="createItem" :loading="loadingCreate">
              {{ $t("create") }}
            </v-btn>
          </validation-observer>
        </v-col>
        <v-col>
          <h3 lass="text--primary">{{ $t("4. Activate Required Modules") }}</h3>
        </v-col>
      </v-row>

      <v-text-field
        v-if="items.length > 10"
        v-model="search"
        append-outer-icon="mdi-magnify"
        clear-icon="mdi-close-circle"
        :disabled="loadingList"
        :clearable="!isMobile"
        v-bind:label="$t('search')"
        single-line
        hide-details
      />

      <v-data-table
        class="mt-8"
        :loading="loadingList"
        item-key="id"
        :items="items"
        :headers="headers"
        :search="search"
        v-if="items.length > 0 || loadingList"
      >
        <template v-slot:item.action="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="
                  $router.push({
                    name: 'net-backup-service-details',
                    params: { productId: item.id },
                  })
                "
                text
                small
                icon
              >
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("show netbackup details") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.remove-underline {
  text-decoration: none;
}
</style>

<script>
import Wiki from "@/components/basics/Wiki";
import isMobile from "@/utils/mixins/isMobile";
import formatDate from "@/utils/mixins/formatDate";
import showErrors from "@/utils/mixins/showErrors";

export default {
  name: "NetBackupList",
  mixins: [formatDate, isMobile, showErrors],
  components: {
    Wiki,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      search: "",
      create: false,
      loadingList: false,
      loadingCreate: false,
      username: "",
      password: "",
      items: [],
      showCurrentPassword: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("Product name"),
          value: "product_name",
        },
        {
          text: this.$i18n.t("description"),
          value: "description",
        },
        {
          text: this.$i18n.t("username"),
          value: "attributes[0].attr_value",
        },
        { text: "", value: "action", sortable: false },
      ];
    },
    loading() {
      return this.loadingList || this.loadingCreate;
    },
  },
  methods: {
    getItems() {
      this.loadingList = true;
      this.$http
        .get("services/net-backup")
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          that.showErrors(error.data);
        })
        .finally(() => {
          this.loadingList = false;
        });
    },
    createItem() {
      this.loadingCreate = true;
      let that = this;
      this.$http
        .post("services/net-backup", {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          that.items.push(response.data);
          that.create = false;
          that.username = "";
          that.password = "";
          that.$router.push({
            name: "net-backup-service-details",
            params: { productId: response.data.id },
          });
        })
        .catch((error) => {
          let err = {
            password: error.data.detail
          }
          that.$refs.obs.setErrors(err)
        })
        .finally(() => {
          this.loadingCreate = false;
        });
    },
  },
  mounted() {
    if (!!this.id) {
      this.search = this.id.toString();
    }
    this.getItems();
  },
};
</script>

<i18n>
{
  "en": {
    "create": "Create",
    "add your backup account": "Add your backup account",
    "show netbackup details": "Show NetBackup details",
    "Product name": "Product name",
    "username": "Username",
    "password": "Password",
    "Download Client": "Download Client",
    "1. Download and Install Client": "1. Download and Install Client",
    "2. Create Trial Account": "2. Create Trial Account",
    "3. Register Trial Account": "3. Register Trial Account",
    "4. Activate Required Modules": "4. Activate Required Modules"
  },
  "de": {
    "create": "Erstellen",
    "add your backup account": "Füge dein Backup Konto hinzu",
    "show netbackup details": "NetBackup Details anzeigen",
    "Product name": "Produktname",
    "username": "Username",
    "password": "Passwort",
    "Download Client": "Client herunterladen",
    "1. Download and Install Client": "1. Client herunterladen und installieren",
    "2. Create Trial Account": "2. Testkonto erstellen",
    "3. Register Trial Account": "3. Testkonto registrieren",
    "4. Activate Required Modules": "4. Benötigte Module aktivieren"
  }
}
</i18n>